// Load the compatibility matrices into memory
import verifiedConfigurations from '../configs/verified.json'

// App store localization schemes
const STORE_LOCALE_SCHEMES = {
  chrome: (url, locale) => `${url}?hl=${locale}`,
  firefox: (url, locale) => {
    try {
      const urlParts = url.split('/')
      // Check if URL has enough parts for valid transformation
      if (urlParts.length < 5) return url
      const [protocol, , domain, , ...rest] = urlParts // Remove unused _locale variable
      return `${protocol}//${domain}/${locale}/${rest.join('/')}`
    } catch (error) {
      return url
    }
  },
  edge: (url, locale) => `${url}?lang=${locale}`,
  // iOS App Store uses different country codes
  ios: (url, locale) => {
    const countryCode = locale.includes('-') ? locale.split('-')[1] : locale
    return url.replace('/app/', `/${countryCode.toLowerCase()}/app/`)
  }
}

// Get localized store URL based on app scheme and locale
export const getLocalizedStoreUrl = (extension, locale) => {
  if (!extension?.appScheme || !locale) return extension.appUrl
  
  const scheme = extension.appScheme.toLowerCase()
  const localizeUrl = STORE_LOCALE_SCHEMES[scheme]
  
  if (!localizeUrl) return extension.appUrl
  
  try {
    return localizeUrl(extension.appUrl, locale)
  } catch (error) {
    console.warn(`Failed to localize store URL for scheme ${scheme}:`, error)
    return extension.appUrl
  }
}

export const getSupportedExtensions = (osName, browserName) => {
  osName = canonicalizeOS(osName)
  let supportedExtensions = []

  verifiedConfigurations.forEach((config) => {
    const browserSatisfies = config.browser.name === browserName
    const platformSatisfies = config.os.includes(osName)
    const nativeExtension = Object.prototype.hasOwnProperty.call(config.extension, 'native') && config.extension.native === true
    if (browserSatisfies && platformSatisfies && !nativeExtension) {
      supportedExtensions = supportedExtensions.concat(config.extension)
    }
  })

  return supportedExtensions
}

export const getSupportedNativeExtensions = (osName, browserName) => {
  const supportedExtensions = getSupportedExtensions(osName, browserName)
  const supportedNativeExtensions = supportedExtensions.filter((extension) => {
    return Object.prototype.hasOwnProperty.call(extension, 'native') && extension.native === true
  })
  return supportedNativeExtensions
}

export const getSupportedUserscriptManagers = (osName, browserName) => {
  const supportedExtensions = getSupportedExtensions(osName, browserName)
  const supportedUsercriptManagers = supportedExtensions.filter((extension) => {
    return Object.prototype.hasOwnProperty.call(extension, 'userscriptManager') && extension.userscriptManager === true
  })
  return supportedUsercriptManagers
}

// Function to get supported browsers based on user's configuration
// arg: Bowser agent
export const getSupportedBrowsers = (osName) => {
  osName = canonicalizeOS(osName)
  const supportedBrowsers = new Set() // Using Set to automatically handle duplicates

  verifiedConfigurations.forEach((config) => {
    // Check if the user's OS is in the list of supported OS for this config
    if (config.os.includes(osName)) {
      supportedBrowsers.add(config.browser)
    }
  })

  return Array.from(supportedBrowsers) // Convert Set back to Array
}

// Function to get browser-specific notes based on the user's browser
export const getBrowserNotes = (osName, browserName) => {
  osName = canonicalizeOS(osName)
  const notes = []

  verifiedConfigurations.forEach((config) => {
    if (config.os.includes(osName) && config.browser.name === browserName && config.browser.note) {
      notes.push(config.browser.note)
    }
  })

  return notes
}

const canonicalizeOS = (osName) => {
  if (osName === 'Mac OS') {
    return 'macOS'
  } else {
    return osName
  }
}
