<template>
  <div class="language-popover-wrapper">
    <span 
      class="language-trigger"
      @mouseenter="show = true"
      @mouseleave="show = false"
      @touchstart.prevent="show = !show"
      @click="show = !show"
    >
      {{ text }}
      <span class="info-icon">ℹ️</span>
    </span>
    <div 
      v-if="show" 
      class="language-popover"
      @mouseenter="show = true"
      @mouseleave="show = false"
      @touchstart.stop
    >
      <h3>{{ t('common.supportedLanguages') }}</h3>
      <div class="languages-grid">
        <div v-for="lang in languages" :key="lang.code" class="language-item">
          {{ lang.name }}
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref } from 'vue';
import { useI18n } from 'vue-i18n';

const { t } = useI18n();
const show = ref(false);

defineProps({
  text: {
    type: String,
    required: true
  }
});

const languages = [
  { code: 'ar', name: 'العربية' },
  { code: 'bg', name: 'Български' },
  { code: 'zh', name: '中文' },
  { code: 'hr', name: 'Hrvatski' },
  { code: 'cs', name: 'Čeština' },
  { code: 'da', name: 'Dansk' },
  { code: 'nl', name: 'Nederlands' },
  { code: 'en', name: 'English' },
  { code: 'tl', name: 'Tagalog' },
  { code: 'fi', name: 'Suomi' },
  { code: 'fr', name: 'Français' },
  { code: 'de', name: 'Deutsch' },
  { code: 'el', name: 'Ελληνικά' },
  { code: 'hi', name: 'हिन्दी' },
  { code: 'hu', name: 'Magyar' },
  { code: 'id', name: 'Bahasa Indonesia' },
  { code: 'it', name: 'Italiano' },
  { code: 'ja', name: '日本語' },
  { code: 'ko', name: '한국어' },
  { code: 'ms', name: 'Bahasa Melayu' },
  { code: 'no', name: 'Norsk' },
  { code: 'pl', name: 'Polski' },
  { code: 'pt', name: 'Português' },
  { code: 'ro', name: 'Română' },
  { code: 'ru', name: 'Русский' },
  { code: 'sk', name: 'Slovenčina' },
  { code: 'es', name: 'Español' },
  { code: 'sv', name: 'Svenska' },
  { code: 'ta', name: 'தமிழ்' },
  { code: 'tr', name: 'Türkçe' },
  { code: 'uk', name: 'Українська' },
  { code: 'vi', name: 'Tiếng Việt' }
];
</script>

<style scoped>
.language-popover-wrapper {
  display: inline-block;
  position: relative;
}

.language-trigger {
  cursor: pointer;
  border-bottom: 1px dashed #418a2f;
  color: #418a2f;
}

.info-icon {
  font-size: 0.8em;
  margin-left: 0.2em;
}

.language-popover {
  position: absolute;
  top: calc(100% + 10px);
  left: 50%;
  transform: translateX(-50%);
  background: white;
  border-radius: 8px;
  box-shadow: 0 2px 12px rgba(0, 0, 0, 0.15);
  padding: 1.25rem;
  z-index: 1000;
  width: 600px;
  max-height: 80vh;
  overflow-y: auto;
}

.language-popover::before {
  content: '';
  position: absolute;
  top: -6px;
  left: 50%;
  transform: translateX(-50%) rotate(45deg);
  width: 12px;
  height: 12px;
  background: white;
  box-shadow: -2px -2px 5px rgba(0, 0, 0, 0.06);
}

.language-popover h3 {
  margin: 0 0 1rem;
  color: #418a2f;
  text-align: center;
  font-size: 1.2em;
}

.languages-grid {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 0.75rem;
  margin: 0 -0.5rem;
}

.language-item {
  padding: 0.5rem;
  text-align: center;
  background: #f5f5f5;
  border-radius: 4px;
  font-size: 0.85em;
  white-space: nowrap;
  min-width: 0;
}

@media (max-width: 768px) {
  .language-popover {
    position: fixed;
    top: 50%;
    left: 43%;
    transform: translate(-50%, -50%);
    width: 80%;
    max-width: 400px;
    max-height: 80vh;
    margin: 0;
    padding: 1rem;
    overflow-y: auto;
    -webkit-overflow-scrolling: touch;
  }

  .languages-grid {
    grid-template-columns: repeat(2, 1fr);
    gap: 0.5rem;
    padding: 0.25rem;
  }

  .language-item {
    padding: 0.5rem 0.25rem;
    font-size: 0.9em;
  }

  .language-popover h3 {
    font-size: 1.1em;
    margin-bottom: 0.75rem;
  }

  /* Add backdrop overlay */
  .language-popover::before {
    content: '';
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.5);
    z-index: -1;
  }
}
</style> 